var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.receipt,
            title: "供应商应付款",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号", prop: "bill_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "单号", disabled: "" },
                        model: {
                          value: _vm.form.bill_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_code", $$v)
                          },
                          expression: "form.bill_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商", prop: "supp_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "供应商", disabled: "" },
                        model: {
                          value: _vm.form.supp_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_name", $$v)
                          },
                          expression: "form.supp_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "\t联系人", prop: "supp_contact" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "\t联系人", disabled: "" },
                        model: {
                          value: _vm.form.supp_contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_contact", $$v)
                          },
                          expression: "form.supp_contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "\t联系人电话", prop: "supp_phone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "\t联系人电话" },
                        model: {
                          value: _vm.form.supp_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_phone", $$v)
                          },
                          expression: "form.supp_phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购单", prop: "order_ori_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "采购单" },
                        model: {
                          value: _vm.form.order_ori_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "order_ori_code", $$v)
                          },
                          expression: "form.order_ori_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间", prop: "create_at" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "下单时间" },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购金额", prop: "bill_amount" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "采购金额" },
                        model: {
                          value: _vm.form.bill_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_amount", $$v)
                          },
                          expression: "form.bill_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "剩余应付款", prop: "arrears_amount" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "\t剩余应付款" },
                        model: {
                          value: _vm.form.arrears_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "arrears_amount", $$v)
                          },
                          expression: "form.arrears_amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.tableData } },
                    _vm._l(_vm.celund, function (item, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.label,
                          align: item.align,
                          prop: item.prop,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showpay == "1",
                          expression: "showpay == '1'",
                        },
                      ],
                      staticStyle: { "margin-top": "20px" },
                      attrs: { label: "本次付款", prop: "pay_amount" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "\t本次付款" },
                        model: {
                          value: _vm.form.pay_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pay_amount", $$v)
                          },
                          expression: "form.pay_amount",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "120px",
                            "margin-left": "20px",
                          },
                          attrs: { placeholder: "请选择付款人" },
                          model: {
                            value: _vm.form.user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_id", $$v)
                            },
                            expression: "form.user_id",
                          },
                        },
                        _vm._l(_vm.userdata, function (list) {
                          return _c("el-option", {
                            key: list.user_id,
                            attrs: {
                              label: list.user_name,
                              value: list.user_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showpay == "1",
                          expression: "showpay == '1'",
                        },
                      ],
                      attrs: { label: "付款账户", prop: "create_at" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择付款账户" },
                          model: {
                            value: _vm.form.account_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account_id", $$v)
                            },
                            expression: "form.account_id",
                          },
                        },
                        _vm._l(_vm.account, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showpay == "1",
                      expression: "showpay == '1'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }