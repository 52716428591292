<template>
  <el-dialog
    title="批量修改"
    :visible.sync="showDialog"
    width="450px"
    top="15vh"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="80px"
        label-suffix=":"
      >
        <!-- 供应商应付款 批量修改 完成form的prop -->
        <el-form-item label="收款顺序" prop="">
          <el-select
            v-model="form.type"
            clearable
            placeholder="请选择收款顺序"
            style="width: 290px"
          >
            <el-option
              v-for="(i, idx) in selectList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应付款" prop="">
          <el-input v-model="sum" disabled style="width: 290px"></el-input>
        </el-form-item>
        <el-form-item label="实付款" prop="">
          <el-input
            v-model.number="form.pay_amount"
            type="number"
            style="width: 140px; margin-right: 10px"
          ></el-input>
          <el-select
            v-model="form.account_id"
            clearable
            style="width: 140px"
            placeholder="选择账户"
          >
            <el-option
              v-for="(i, idx) in accountSelectList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款人" prop="">
          <el-select
            v-model="form.user_id"
            clearable
            style="width: 290px"
            placeholder="付款人"
          >
            <el-option
              v-for="(i, idx) in peopleSelect"
              :key="idx"
              :value="i.user_id"
              :label="i.user_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="">
          <el-input
            v-model="form.remark"
            type="textarea"
            rows="5"
            style="width: 290px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-top: 20px; margin-right: 30px; text-align: right">
        <el-button type="primary" @click="handleSave">提交</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import { accountList } from '@/api/financial'
  import { defaultStaff } from '@/api/setPrice'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        arrears_ids: '',
        accountSelectList: [],
        peopleSelect: [],
        sum: 0,
        selectList: [
          {
            id: 0,
            name: '由远到近',
          },
          {
            id: 1,
            name: '由近到远',
          },
        ],
        // type 0由远到近 1由近到远
        form: {
          type: '',
          remark: '',
          pay_amount: '',
          user_id: '',
          account_id: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.sum = 0
          this.arrears_ids = ''
          this.form = {
            type: '',
            remark: '',
            pay_amount: '',
            user_id: '',
            account_id: '',
          }
        } else {
          this.form.pay_amount = Number(JSON.parse(JSON.stringify(this.sum)))
        }
      },
    },
    created() {},
    mounted() {
      // 账户下拉
      accountList().then((d) => {
        this.accountSelectList = d.data
      })
      // 员工下拉
      defaultStaff().then((res) => {
        this.peopleSelect = res.data
      })
    },
    methods: {
      handleSave() {
        // 应付款批量付款接口地址
        postAction('cashAdmin/supp/go-pay', {
          ...this.form,
          ...{ arrears_ids: this.arrears_ids },
        }).then(() => {
          this.$message.success('付款成功')
          this.$emit('refresh')
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .a {
    display: flex;
  }
  .bv {
    display: inline-block;
  }
</style>
