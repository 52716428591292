<!--
 * @Author: your name
 * @Date: 2021-02-08 14:37:44
 * @LastEditTime: 2021-03-29 09:17:34
 * @LastEditors: Please set LastEditors
 * @Description: 供应商应付款
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\payable\components\jiePay.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="receipt"
      title="供应商应付款"
      center
    >
      <div>
        <el-form ref="form" :model="form" inline label-width="150px">
          <el-form-item label="单号" prop="bill_code">
            <el-input
              v-model="form.bill_code"
              placeholder="单号"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="供应商" prop="supp_name">
            <el-input
              v-model="form.supp_name"
              placeholder="供应商"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="	联系人" prop="supp_contact">
            <el-input
              v-model="form.supp_contact"
              placeholder="	联系人"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="	联系人电话" prop="supp_phone">
            <el-input
              v-model="form.supp_phone"
              disabled
              placeholder="	联系人电话"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="采购单" prop="order_ori_code">
            <el-input
              v-model="form.order_ori_code"
              disabled
              placeholder="采购单"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="下单时间" prop="create_at">
            <el-input
              v-model="form.create_at"
              disabled
              placeholder="下单时间"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="采购金额" prop="bill_amount">
            <el-input
              v-model="form.bill_amount"
              disabled
              placeholder="采购金额"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="剩余应付款" prop="arrears_amount">
            <el-input
              v-model="form.arrears_amount"
              disabled
              placeholder="	剩余应付款"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-table stripe :data="tableData">
            <el-table-column
              v-for="(item, index) in celund"
              :key="index"
              :label="item.label"
              :align="item.align"
              :prop="item.prop"
            ></el-table-column>
          </el-table>
          <el-form-item
            v-show="showpay == '1'"
            label="本次付款"
            prop="pay_amount"
            style="margin-top: 20px"
          >
            <el-input
              v-model="form.pay_amount"
              placeholder="	本次付款"
              style="width: 120px"
            ></el-input>
            <el-select
              v-model="form.user_id"
              placeholder="请选择付款人"
              style="width: 120px; margin-left: 20px"
            >
              <el-option
                v-for="list in userdata"
                :key="list.user_id"
                :label="list.user_name"
                :value="list.user_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div></div>
          <el-form-item
            v-show="showpay == '1'"
            label="付款账户"
            prop="create_at"
          >
            <el-select v-model="form.account_id" placeholder="请选择付款账户">
              <el-option
                v-for="list in account"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item v-show="showpay == '1'" label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="备注"
              style="width: 420px"
            ></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="showpay == '1'" type="primary" @click="handleSave">
          保 存
        </el-button>
        <el-button @click="receipt = !receipt">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        showpay: '1',
        receipt: false,
        url: {
          supptable: '/cashAdmin/supp/payment-add-list',
          userlist: '/baseAdmin/common/user-list',
          account: '/baseAdmin/common/account',
          info: '/cashAdmin/supp/arrears-detail',
          sub: '/cashAdmin/supp/go-pay',
          // 供应商应付款-付款
        },
        form: {
          bill_code: '',
          supp_name: '',
          supp_contact: '',
          supp_phone: '',
          order_ori_code: '',
          create_at: '',
          bill_amount: '',
          arrears_amount: '',
          //什么提交的数据
          supp_id: '', //供应商ID
          arrears_ids: '', //应付款单ids,用逗号隔开
          pay_amount: '', //实付款
          user_id: '', //付款人id
          account_id: '', //付款账户id
          remark: '', //备注
        },

        account: [],
        userdata: [],
        tableData: [],
        celund: [
          {
            label: '付款人',
            align: 'center',
            prop: 'create_name',
          },
          {
            label: '付款金额',
            align: 'center',
            prop: 'amount',
          },
          {
            label: '付款账户',
            align: 'center',
            prop: 'account_name',
          },
          {
            label: '付款时间',
            align: 'center',
            prop: 'create_at',
          },
        ],
        sup: {
          id: '',
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        summe: [],
      }
    },
    computed: {
      money() {
        var sum = 0
        this.summe.forEach((list) => {
          sum += Number(list.arrears_amount)
        })
        return sum
      },
    },
    watch: {
      receipt(val) {
        if (!val) {
          this.$emit('getlist')
        }
      },
    },
    mounted() {
      this.hanlderuserlist()
      this.handleraccount()
    },
    methods: {
      handlerData(row) {
        console.log(row, '')
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res, '')
            this.form = res.data
            this.tableData = res.data.payment_log.list
            if (res.code == 200) {
            } else {
              this.$message({
                message: '这个是接口的错',
                type: 'error',
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 收款账户
      handleraccount() {
        postAction(this.url.account, {})
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSave() {
        let end = {
          arrears_ids: this.form.id,
          account_id: this.form.account_id,
          pay_amount: this.form.pay_amount,
          user_id: this.form.user_id,
          remark: this.form.remark,
        }

        postAction(this.url.sub, end).then((res) => {
          this.receipt = false
          this.$message.success(res.msg)
        })
      },
    },
  }
</script>

<style></style>
